<template>
<div>
  <b-container v-if="isLoading" class="text-center d-flex justify-content-center">
  </b-container>
  <div v-else>
    <div class="w-menu">
      <b-row class="m-menu-avatar w-cursor-click" @click="gotoProfile()">
        <b-col>
          <b-avatar v-if="currentProfilePicture" 
            size="50px" 
            v-bind:src="currentProfilePicture"
          >
          </b-avatar>
          <b-avatar v-else 
            size="50px" 
            :src="require('@/assets/img/profile-picture.jpg')"
          >
          </b-avatar>
        </b-col>
      </b-row>
      <b-row class="m-menu-under-avatar">
        <b-col style="padding:0" class="text-center">
            <p class="w-menu-name">{{currentUser.firstname}} {{currentUser.lastname}}</p>
            <p class="w-menu-email">{{currentUser.email}}</p>
        </b-col>
      </b-row>
      <b-row class="d-flex flex-row justify-content-center" style="width:100%; margin:0">  
        <div class="justify-content-left">
          <p class="w-menuitem-1" style="top:191px;color:white">information </p>
          <p class="w-menuitem-3 w-cursor-click" style="top:228px" @click="gotoWelcome()">information</p>
          <p class="w-menuitem-2 w-cursor-click" style="top:265px" @click="gotoGeneral()">general</p>
          <p class="w-menuitem-2 w-cursor-click" style="top:302px" @click="gotoContact()">contact</p>
          <p class="w-menuitem-2 w-cursor-click" style="top:339px" @click="gotoSocialMedia()">social media</p>
          <p class="w-menuitem-2 w-cursor-click" style="top:376px" @click="gotoPictures()">gallery</p>
          <p class="w-menuitem-2 w-cursor-click" style="top:413px" @click="gotoWines()">wines</p>
 
          <p class="w-menuitem-3 w-cursor-click" style="top:450px" @click="gotoPreview()">preview</p>
          <p class="w-menuitem-3 w-cursor-click" style="top:487px" @click="gotoAboutUs()">about us</p>  
          <p class="w-menuitem-3 w-cursor-click" style="top:524px" @click="logOut()">log off</p>
        </div>
        <div v-if="this.currentPage=='General'" class="w-current-item-mobile" style="top:263px"/>
        <div v-if="this.currentPage=='Contact'" class="w-current-item-mobile" style="top:300px"/>
        <div v-if="this.currentPage=='SocialMedia'" class="w-current-item-mobile" style="top:337px"/>
        <div v-if="this.currentPage=='Pictures'" class="w-current-item-mobile" style="top:374px"/>
        <div v-if="this.currentPage=='Wines'" class="w-current-item-mobile" style="top:411px"/>
        <div v-if="this.currentPage=='Preview'" class="w-current-item-mobile" style="top:448px"/>
        <div v-if="this.currentPage=='AboutUs'" class="w-current-item-mobile" style="top:485px"/>
      </b-row>
      
    </div>
  </div>
</div>
</template>

<script>
  export default {
    computed : {
      currentUser() {
        return this.$store.state.user
      },
      currentProfilePicture() {
        return this.$store.state.profile_picture
      },
      currentUserID() {
        return this.$store.state.user_id
      },
      currentPage() {
        return this.$store.state.current_page
      },
      previousPage() {
        return this.$store.state.previous_page
      }
    },
    data() {
      return {
        isLoading : true,
      }
    },
    async mounted() {
      this.isLoading = false
    },
    methods:{
      gotoProfile() {
        this.$store.commit('setPage','Profile')
        this.$router.push({path:'/Profile', query: {winery_id:this.$route.query.winery_id}})
      },
      gotoHome() {
        this.$store.commit('setPage','Welcome')
        this.$router.push({path:'/Winery/Welcome', query: {winery_id:this.$route.query.winery_id}})
      },
      logOut() {
        this.$store.commit('logout')
        this.$router.push({path:'/'})
      },
      gotoWelcome() {
        this.$store.commit('setPage','Welcome')
        this.$router.push({path:'/Winery/Welcome', query: {winery_id:this.$route.query.winery_id }} );
      },
      gotoGeneral() {
        this.$store.commit('setPage','General')
        this.$router.push({path:'/Winery/General', query: {winery_id:this.$route.query.winery_id }} );
      },
      gotoContact() {
        this.$store.commit('setPage','Contact')
        this.$router.push({path:'/Winery/Contact', query: {winery_id:this.$route.query.winery_id }} );
      },
      gotoSocialMedia() {
        this.$store.commit('setPage','SocialMedia')
        this.$router.push({path:'/Winery/SocialMedia', query: {winery_id:this.$route.query.winery_id }} );
      },
      gotoPictures() {
        this.$store.commit('setPage','Pictures')
        this.$router.push({path:'/Winery/Pictures', query: {winery_id:this.$route.query.winery_id }} );
      },
      gotoWines() {
        this.$store.commit('setPage','Wines')
        this.$router.push({path:'/Winery/Wines', query: {winery_id:this.$route.query.winery_id }} );
      },
      gotoPreview() {
        this.$store.commit('setPage','Preview')
        this.$router.push({path:'/Winery/Preview', query: {winery_id:this.$route.query.winery_id }} );
      },
      gotoAboutUs() {
        this.$store.commit('setPage','AboutUs')
        this.$router.push({path:'/AboutUs'} );
      },      
    }
  }
</script>

<style>
@import url("../assets/styles/wynestyle.css");
</style>

<style scoped>
</style>